<template>
  <div>
    <component
      :is="computedComponentName"
      v-model="shouldShowModal"
      :close-on-content-click="false"
      :min-width="computedComponentName === 'v-menu' ? 240 : null"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="d-flex align-center justify-between filter-title"
          :class="{ 'font-weight-bold': isFilterApplied }"
          v-on="on"
          v-bind="attrs"
        >
          <div class="d-flex align-center flex-grow-1">
            <v-icon left> update </v-icon>

            Expired
          </div>
          <v-icon> expand_more </v-icon>
        </div>
      </template>
      <v-card min-width="240">
        <v-card-title class="d-md-none"> Account Type </v-card-title>

        <v-card-text class="pt-md-3" :class="{ 'black--text': isThemeLight }">
          <div class="mb-1 d-flex">
            <div class="d-flex align-center flex-grow-1">
              <v-icon :class="{ 'black--text': isThemeLight }" class="mr-1" left> update </v-icon>

              Select a value
            </div>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon :color="isThemeLight ? 'black' : null" v-bind="attrs" v-on="on"> info </v-icon>
              </template>

              <span>
                {{ tooltipText }}
              </span>
            </v-tooltip>
          </div>

          <v-radio-group v-model="form.selectedIndex" @change="submitForm" hide-details>
            <v-radio v-for="(item, index) in options" :key="index" :label="item.label" :value="index"></v-radio>
          </v-radio-group>
        </v-card-text>
      </v-card>
    </component>
  </div>
</template>

<script>
// used throughout the code
const filterName = "isExpired"

const originalForm = () => ({
  selectedIndex: null
})

export default {
  name: "FilterIsExpired",

  data: () => ({
    form: originalForm(),

    shouldShowModal: false,

    options: [
      {
        label: "Expiring in 7 days",
        value: 7
      },
      {
        label: "Expiring in 2 weeks",
        value: 14
      },
      {
        label: "Expiring in 1 month",
        value: 30
      },
      {
        label: "Expired",
        value: 0
      }
    ],

    tooltipText: "Whether or not the account has been expired or will be expired"
  }),

  computed: {
    // show the filter as a dialog for mobile dialogs
    computedComponentName() {
      return this.isDesktopDevice ? "v-menu" : "v-dialog"
    },

    // Used to bold the filter name text
    isFilterApplied() {
      return Boolean(this.$store.getters["admin/users/findFilterByType"](filterName))
    }
  },

  methods: {
    /**
     * Takes an item and generates the label for it
     */
    computeFilterText({ label }) {
      return `Validity: ${label}`
    },

    /**
     * Push the data from either of the inputs to the filters array in Vuex Store
     */
    async submitForm() {
      const selectedItem = this.options[this.form.selectedIndex]

      // if there's some value, then update the filter
      if (selectedItem) {
        this.$store.dispatch("admin/users/replaceFilter", {
          type: filterName,
          data: {
            color: "pink lighten-5",
            icon: "update",
            iconColor: "pink",
            text: this.computeFilterText({ label: selectedItem.label }),
            inputs: {
              ...selectedItem
            }
          }
        })
      }
    }
  },

  mounted() {
    // subscribe to window event when the chip is closed
    window.addEventListener("removeFilter", (e) => {
      // if it's not the one we want, don't execute the code
      if (e.detail.module !== "admin/users" || e.detail.item.type !== filterName) return

      // now that it is the one we want to see, continue
      this.form = originalForm()
    })

    // subscribe to window event when the chip is closed
    window.addEventListener("addFilter", (e) => {
      // if it's not the one we want, don't execute the code
      if (e.detail.module !== "admin/users" || e.detail.item.type !== filterName) return

      // now that it is the one we want to see, continue
      const index = this.options.findIndex((search) => search.value === e.detail.item.data.inputs.value)
      this.form.selectedIndex = index
    })
  }
}
</script>
